import {Component,createRef} from 'react';
import {Menu,Button,Icon,Modal,Form} from 'semantic-ui-react';
import ReactFlow,{MiniMap,Controls,ControlButton,Background,useReactFlow,ReactFlowProvider,MarkerType} from 'reactflow';
import DiagramNode from './DiagramNode';
import NodeEditDialog from './NodeEditDlg';
import 'reactflow/dist/style.css';
import firebase from 'firebase/compat/app';
import Dagre from '@dagrejs/dagre';

class FlowDiagram extends Component{
  constructor(props){
    super(props);
    this.state = {
      nodes:[],
      edges:[],
      showContextMenu: false,
      menuX: 200,
      menuY: 200,
      showNodeDialog: false,
      currentNode: {}
    };
    this.flowRef = createRef();
    this.connectingNodeId=null;
    this.updatingEdge=false;
    this.edgeUpdated=false;

    const customNode = (props)=>{
      return(<DiagramNode {...props} onEdit={this.onEditNode.bind(this)}/>);
    };
    this.nodeType={default: customNode}
  }
  componentDidMount(){
    const self = this;
    const refNodes = firebase.database().ref('/diagram/nodes/'+this.props.user.uid);
    //+'/'+this.props.roadmapId);
    refNodes.on('value',function(s){
      let items = [];
      let child = s.child(self.props.roadmapId);
      child.forEach(function(item){
        let data = item.val().data?item.val().data:{};
        data['userId']=self.props.user.uid;
        data['roadmapId']=self.props.roadmapId;
        items.push({
          ...item.val(),
          id:item.key,
          data: data
        });
      });
      self.setState({nodes:items});
    });
    const refEdges = firebase.database().ref('/diagram/edges/'+this.props.user.uid);
    refEdges.on('value',function(s){
      let items = [];
      let child = s.child(self.props.roadmapId);
      child.forEach(function(item){
        items.push({
          ...item.val(),
          id:item.key
        });
      });
      self.setState({edges:items});
    });
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.roadmapId!==this.props.roadmapId){
      const self = this;
      const refNodes = firebase.database().ref('/diagram/nodes/'+this.props.user.uid+'/'+nextProps.roadmapId);
      refNodes.once('value').then(function(s){
        let items = [];
        s.forEach(function(item){
          let data = item.val().data?item.val().data:{};
          data['userId']=self.props.user.uid;
          data['roadmapId']=nextProps.roadmapId;
          items.push({
            ...item.val(),
            id:item.key,
            data:data
          });
        });
        self.setState({nodes:items});
      }).catch(function(error){
        console.error(error)
      });
      const refEdges = firebase.database().ref('/diagram/edges/'+this.props.user.uid+'/'+nextProps.roadmapId);
      refEdges.once('value').then(function(s){
        let items = [];
        s.forEach(function(item){
          items.push({
            ...item.val(),
            id:item.key
          });
        });
        self.setState({edges:items});
      }).catch(function(error){
        console.error(error)
      });
    }
  }
  addNewNode(attr){
    if(!this.props.roadmapId)
      return;
    const refNodes = firebase.database().ref('/diagram/nodes/'+this.props.user.uid+'/'+this.props.roadmapId);
    const ref = refNodes.push();
    let position = {x:0,y:0}
    if(attr && attr.position)
      position = attr.position;
    ref.set({
      position: position
    });
    return ref.key;
  }
  addNewEdge(attr){
    if(!this.props.roadmapId)
      return;
    if(!attr.source || !attr.target)
      return;
    
    const refNodes = firebase.database().ref('/diagram/edges/'+this.props.user.uid+'/'+this.props.roadmapId);
    const ref = refNodes.push();
    ref.set({
      source: attr.source,
      target: attr.target,
      animated: false,
      updatable: 'target',
      interactionWidth: 40,
      markerEnd:{
        type: MarkerType.ArrowClosed,
        width:20,
        height:20
      }
    });
    return ref.key;
  }
  onNodesChange(changes){
    const self = this;
    changes.forEach(function(change){
      const refNode = firebase.database().ref('/diagram/nodes/'+self.props.user.uid+'/'+self.props.roadmapId+'/'+change.id);
      switch(change.type){
        case 'position':
          if(change.position)
            refNode.update({position: change.position})
          break;
        default:
          break;
      }
    });
  }
  onConnect(connection){
    this.addNewEdge({source:connection.source,target:connection.target});
  }
  onConnectStart(event,params){
    this.connectingNodeId = params.nodeId;
  }
  onConnectEnd(event){
    //moving edge
    if(this.updatingEdge)
      return;

    const targetIsPane = event.target.classList.contains('react-flow__pane');
    if (targetIsPane) {
      // we need to remove the wrapper bounds, in order to get the correct position
      const { top, left } = this.flowRef.current.getBoundingClientRect();
      const newNodeId = this.addNewNode({position:this.props.instance.project({x:event.clientX-left,y:event.clientY-top})});
      this.addNewEdge({source:this.connectingNodeId,target:newNodeId});
    }
  }
  onEdgeUpdateStart(){
    this.updatingEdge=true;
    this.edgeUpdated=false;
  }
  onEdgeUpdate(oldEdge,newConnection){
    const ref = firebase.database().ref('/diagram/edges/'+this.props.user.uid+'/'+this.props.roadmapId+'/'+oldEdge.id);
    ref.update({
      target: newConnection.target
    });
    this.edgeUpdated=true;
  }
  onEdgeUpdateEnd(e,edge){
    if(!this.edgeUpdated)
    {
      //if edge is dropped to empty space, we remove it
      const ref = firebase.database().ref('/diagram/edges/'+this.props.user.uid+'/'+this.props.roadmapId+'/'+edge.id);
      ref.remove();
    }
    this.updatingEdge=false;
  }
  onContextMenu(e){
    e.preventDefault();
    this.setState({
      showContextMenu:true,
      menuX: e.clientX,
      menuY: e.clientY
    });
  }
  onClick(e){
    this.setState({
      showContextMenu: false
    });
  }
  onNewNode(){
      const { top, left } = this.flowRef.current.getBoundingClientRect();
      this.addNewNode({position:this.props.instance.project({x:this.state.menuX-left,y:this.state.menuY-top})});
  }
  onArrange(){
    let nodes = this.state.nodes;
    let edges = this.state.edges;
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(()=>({}));
    g.setGraph({
      rankdir: 'LR'
    });
    edges.forEach(function(edge){
      g.setEdge(edge.source,edge.target);
    });
    nodes.forEach(function(node){
      g.setNode(node.id,{width: 172, height:56});
    });
    Dagre.layout(g);
    nodes = nodes.map(function(node){
      const {x,y} = g.node(node.id);
      return {...node, position: {x,y}};
    });
    //this.setState({nodes:nodes,edges:edges});

    const self = this;
    nodes.forEach(function(node){
      const ref = firebase.database().ref('/diagram/nodes/'+self.props.user.uid+'/'+self.props.roadmapId+'/'+node.id);
      ref.update({
        position: node.position
      });
    });
  }
  onEditNode(node){
    this.setState({
      showNodeDialog: true,
      currentNode: node
    });
  }
  onClose(){
    this.setState({showNodeDialog: false});
  }
  render(){

    let menu;
    if(this.state.showContextMenu){
      menu = (
        <Menu vertical className='context-menu' style={{left:this.state.menuX,top:this.state.menuY}} >
          <Menu.Item onClick={this.onNewNode.bind(this)}><Icon name='plus'/>New Node</Menu.Item>
          <Menu.Item onClick={this.onArrange.bind(this)}><Icon name='block layout'/>Arrange Nodes</Menu.Item>
        </Menu>
      );
    }

    return (
      <div className='diagram' ref={this.flowRef} onContextMenu={this.onContextMenu.bind(this)} onClick={this.onClick.bind(this)}>
        <ReactFlow
          nodes={this.state.nodes} edges={this.state.edges}
          fitView={true} snapToGrid={true}
          nodeTypes={this.nodeType}
          onNodesChange={this.onNodesChange.bind(this)}
          onConnect={this.onConnect.bind(this)}
          onConnectStart={this.onConnectStart.bind(this)}
          onConnectEnd={this.onConnectEnd.bind(this)}
          onEdgeUpdate={this.onEdgeUpdate.bind(this)}
          onEdgeUpdateStart={this.onEdgeUpdateStart.bind(this)}
          onEdgeUpdateEnd={this.onEdgeUpdateEnd.bind(this)}
        >
          <Controls showInteractive={false}>
            <ControlButton onClick={this.onArrange.bind(this)}>
              <Icon name='block layout' fitted/>
            </ControlButton>
          </Controls>
          <MiniMap pannable={true}/>
          <Background variant='dots' gap={15} size={1}/>
        </ReactFlow>
        {menu}
        <NodeEditDialog open={this.state.showNodeDialog} onClose={this.onClose.bind(this)} data={this.state.currentNode}/>
      </div>
    );
  }
}
//all those following trouble to get current react-flow instance state within FlowDiagram component
function Flow(props){
  const flowInstance = useReactFlow();
  return <FlowDiagram instance={flowInstance} {...props}/>;
}
function FlowWithProvider(props){
  return(
    <ReactFlowProvider>
      <Flow {...props}/>
    </ReactFlowProvider>
  );
}
export default FlowWithProvider;
