import {Component} from 'react';
import {Card,Button} from 'semantic-ui-react';
export default class NodeCard extends Component{
  onClose(){
    if(this.props.onClose)
      this.props.onClose();
  }
  render(){
    console.log('props:',this.props);
    return(
      <Card style={{borderColor: this.props.color}}>
        <Card.Content textAlign='center'>
          <Button icon='close' color='red' size='mini' className='close-button' onClick={this.onClose.bind(this)}/>
          <Card.Description>{this.props.nodeLabel}</Card.Description>
        </Card.Content>
        <Card.Content extra textAlign='center'>
          {this.props.roadmapLabel}
        </Card.Content>
      </Card>
    );
  }
}
