import {Component} from 'react';
import {Grid} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import Task from './StaticTask';

export default class Timeslot extends Component{
  constructor(props){
    super(props);
    this.state={
      tasks: []
    }
  }
  componentDidMount(){
    const self = this;
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid);
    ref.orderByChild('scheduled/'+this.props.id).equalTo(true).on('value',function(s){
      let items=[];
      s.forEach(function(item){
        items.push({
          id: item.key,
          ...item.val()
        });
      });
      self.setState({
        tasks: items
      });
    });
  }
  onDrop(e){
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('data'));
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid+'/'+data.id+'/scheduled/');
    if(e.dataTransfer.dropEffect==='move'){ 
      const from = e.dataTransfer.getData('from');
      ref.update({
        [from]: null,
        [this.props.id]: true
      });
    }
    else{
      ref.update({
        [this.props.id]: true
      });
    }
  }
  onDragOver(e){
    e.preventDefault();
  }
  render(){
    const self = this;
    const tasks = this.state.tasks.map(function(task){
      return(<Task key={task.id} timeslotid={self.props.id} data={task} user={self.props.user}/>);
    });
    return (
      <div className='timeslot' onDrop={this.onDrop.bind(this)} onDragOver={this.onDragOver.bind(this)}>
        <div className='label'>{this.props.label}</div>
        {tasks}
      </div>
    );
  }
};
