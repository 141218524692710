import {Component} from 'react';
import {Grid,Button} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import Task from './EditableTask';

export default class role extends Component{
  constructor(props){
    super(props);
    this.state={
      tasks: []
    }
  }
  componentDidMount(){
    const self = this;
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid);
    ref.orderByChild('parent').equalTo(this.props.item.id).on('value',function(s){
      let items=[];
      s.forEach(function(item){
        items.push({
          id: item.key,
          ...item.val()
        });
      });
      self.setState({
        tasks: items
      });
    });
  }
  onAddClicked(){
    const self = this;
    let ref = firebase.database().ref('/tasks/'+this.props.user.uid);
    ref = ref.push();
    ref.set({
      parent: this.props.item.id,
      scheduled: false,
      priority: 'D1'
    });
  }
  render(){
    const self = this;
    return (
      <div className='role-item'>
        <div className='role'>
          <Button.Group size='mini' className='toolbar'>
            <Button icon='plus' color='blue' onClick={this.onAddClicked.bind(this)}/>
          </Button.Group>
          {this.props.item.name}
        </div>
        <div className='task'>
          {this.state.tasks.map(function(task,i){
            return(<Task key={i} data={task} user={self.props.user}/>)
          })}
        </div>
      </div>
    );
  }
};
