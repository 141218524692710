import {Component} from 'react';
import {Grid,Header} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import Role from './Role';
import Timeslot from './Timeslot';
import './style.scss';

export default class Schedule extends Component{
  constructor(props){
    super(props);
    this.state={
      roles: []
    }
  }
  componentDidMount(){
    const self = this;
    const ref = firebase.database().ref('/roles/'+this.props.user.uid);
    ref.on('value',function(s){
      let items=[];
      s.forEach(function(item){
        items.push({
          id: item.key,
          ...item.val()
        });
      });
      self.setState({
        roles: items
      });
    });
  }
  getTimedSlots(day){
    return Array.from(
      {length: (23-6)+1},
      (value,i) => (<Timeslot user={this.props.user} key={i} id={'timed-'+day+'-'+i} label={(6+i)+':00'}/>)
    );
  }
  render(){
    const self = this;
    const roles = this.state.roles.map(function(role){
      return(<Role key={role.id} item={role} user={self.props.user}/>);
    });
    return (
      <Grid className='schedule' celled='internally'>
        <Grid.Row>
          <Grid.Column width={2} className='sidebar'>
            <h5 className='title'>ROLES=>TASKS</h5>
            {roles}
            <div className='improve'>
              <h5 className='title'>IMPROVE/LEARN/EXCERCISE</h5>
              <Role key='physical' item={{id:'physical',name:'Physical'}} user={this.props.user}/>
              <Role key='technical' item={{id:'technical',name:'Technical'}} user={this.props.user}/>
              <Role key='mindpower' item={{id:'mindpower',name:'Mind Power'}} user={this.props.user}/>
              <Role key='human interaction' item={{id:'humaninteraction',name:'Human Interaction'}} user={this.props.user}/>
            </div>
          </Grid.Column>
          <Grid.Column width={14}>
            <Grid divided columns='equal' className='day-grid'>
              <Grid.Row stretched>
                <Grid.Column>
                  <div className='day-header'>Mon</div>
                  <Timeslot user={this.props.user} id='mon-0'/>
                  <Timeslot user={this.props.user} id='mon-1'/>
                  <Timeslot user={this.props.user} id='mon-2'/>
                  <Timeslot user={this.props.user} id='mon-3'/>
                </Grid.Column>
                <Grid.Column>
                  <div className='day-header'>Tue</div>
                  <Timeslot user={this.props.user} id='tue-0'/>
                  <Timeslot user={this.props.user} id='tue-1'/>
                  <Timeslot user={this.props.user} id='tue-2'/>
                  <Timeslot user={this.props.user} id='tue-3'/>
                </Grid.Column>
                <Grid.Column>
                  <div className='day-header'>Wed</div>
                  <Timeslot user={this.props.user} id='wed-0'/>
                  <Timeslot user={this.props.user} id='wed-1'/>
                  <Timeslot user={this.props.user} id='wed-2'/>
                  <Timeslot user={this.props.user} id='wed-3'/>
                </Grid.Column>
                <Grid.Column>
                  <div className='day-header'>Thu</div>
                  <Timeslot user={this.props.user} id='thu-0'/>
                  <Timeslot user={this.props.user} id='thu-1'/>
                  <Timeslot user={this.props.user} id='thu-2'/>
                  <Timeslot user={this.props.user} id='thu-3'/>
                </Grid.Column>
                <Grid.Column>
                  <div className='day-header'>Fri</div>
                  <Timeslot user={this.props.user} id='fri-0'/>
                  <Timeslot user={this.props.user} id='fri-1'/>
                  <Timeslot user={this.props.user} id='fri-2'/>
                  <Timeslot user={this.props.user} id='fri-3'/>
                </Grid.Column>
                <Grid.Column>
                  <div className='day-header'>Sat</div>
                  <Timeslot user={this.props.user} id='sat-0'/>
                  <Timeslot user={this.props.user} id='sat-1'/>
                  <Timeslot user={this.props.user} id='sat-2'/>
                  <Timeslot user={this.props.user} id='sat-3'/>
                </Grid.Column>
                <Grid.Column>
                  <div className='day-header'>Sun</div>
                  <Timeslot user={this.props.user} id='sun-0'/>
                  <Timeslot user={this.props.user} id='sun-1'/>
                  <Timeslot user={this.props.user} id='sun-2'/>
                  <Timeslot user={this.props.user} id='sun-3'/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column>
                  {this.getTimedSlots('mon')}
                </Grid.Column>
                <Grid.Column>
                  {this.getTimedSlots('tue')}
                </Grid.Column>
                <Grid.Column>
                  {this.getTimedSlots('wed')}
                </Grid.Column>
                <Grid.Column>
                  {this.getTimedSlots('thu')}
                </Grid.Column>
                <Grid.Column>
                  {this.getTimedSlots('fri')}
                </Grid.Column>
                <Grid.Column>
                  {this.getTimedSlots('sat')}
                </Grid.Column>
                <Grid.Column>
                  {this.getTimedSlots('sun')}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};
