import {Component} from 'react';
import './style.scss';
import {Container,Header,Accordion} from 'semantic-ui-react';
export default class help extends Component{
  render(){
    return (
      <Container className='help'>
        <Header as='h4'>Adding new roadmap</Header>
        <p>add button</p>
        <Header as='h4'>editing roadmap</Header>
        <p>hover roadmap, click edit button</p>
        <Header as='h4'>Adding New Node</Header>
        <p>
          right click -> new node
          <br/>
          <strong>or</strong>
          <br/>
          Drag node right handle, drop it somewhere
        </p>
        <Header as='h4'>Deleting Node</Header>
        <p>Select node, click trash button</p>
        <Header as='h4'>Change Node text</Header>
        <p>Select node, click edit button</p>
        <Header as='h4'>Create sub-roadmap</Header>
        <p>Select node, click pin button</p>
        <Header as='h4'>Connect nodes</Header>
        <p>drag node right handle, drop on another node's left handle</p>
        <Header as='h4'>delete connection</Header>
        <p>drag right side (ending side) of the line, drop it to empty space</p>
        <Header as='h4'>auto layout</Header>
        <p>from bottom left, select button with grid icon</p>
      </Container>
    );
  }
};
