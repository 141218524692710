import {Component} from 'react';
import {Grid,Button,Label,Icon} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
export default class Task extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount(){
    /*
    const self = this;
    const ref = firebase.database().ref('/roles/'+this.props.user.uid);
    ref.on('value',function(s){
      let items=[];
      s.forEach(function(item){
        items.push({
          id: item.key,
          ...item.val()
        });
      });
      self.setState({
        roles: items
      });
    });
    */
  }
  onDelete(){
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid+'/'+this.props.data.id+'/scheduled');
    ref.update({
      [this.props.timeslotid]: null
    });
  }
  onDragStart(e){
    e.dataTransfer.setData('data',JSON.stringify(this.props.data));
    e.dataTransfer.setData('from',this.props.timeslotid);
    e.dataTransfer.effectAllowed = 'move';
  }
  render(){
    const priority = this.props.data.priority || 'D1';
    const priorityColor = ['red','orange','teal','blue'][priority.charCodeAt(0)-65];
    return (
      <Label tag  size='mini' color={priorityColor} className='static-task' draggable='true' onDragStart={this.onDragStart.bind(this)}>
        {this.props.data.label}
        <Icon name='close'onClick={this.onDelete.bind(this)}/>
      </Label>
    );
  }
};
