import {Component} from 'react';
import {Grid,Dropdown,Modal,List,Portal,Segment,Menu,Button,Input,Icon,Header} from 'semantic-ui-react';
import {Link} from 'wouter';
import firebase from 'firebase/compat/app';

export default class NodeSelector extends Component{
  constructor(props){
    super(props);
    this.state = {
      roadmaps: [],
      currentRoadmap: null,
      nodes: []
    };
  }
  componentDidMount(){
    const self = this;
    const ref = firebase.database().ref('/roadmaps/'+this.props.user.uid);
    ref.on('value',function(s){
      let items=[];
      s.forEach(function(item){
        items.push({
          id: item.key,
          ...item.val()
        });
      });
      self.setState({
        roadmaps: items
      });
    });

    /*
    const refNodes = firebase.database().ref('/diagram/nodes/'+this.props.user.uid);
    refNodes.on('value',function(s){
      s.forEach(function(rm){  
        const roadmapId = rm.key;
        for(const [key,value] of Object.entries(rm.val())){

        }
      });
      */

        /*
      let items = [];
      let child = s.child(self.props.roadmapId);
      child.forEach(function(item){
        let data = item.val().data?item.val().data:{};
        data['userId']=self.props.user.uid;
        data['roadmapId']=self.props.roadmapId;
        items.push({
          ...item.val(),
          id:item.key,
          data: data
        });
      });
        */
  }
  componentDidUpdate(prevProps,prevState){
    if(prevState.currentRoadmap!==this.state.currentRoadmap){
      const self = this;
      const ref = firebase.database().ref('/diagram/nodes/'+this.props.user.uid+'/'+this.state.currentRoadmap);
      ref.once('value').then(function(s){
        let items = [];
        s.forEach(function(item){
          let data = item.val().data?item.val().data:{};
          data['roadmapId']=self.props.roadmapId;
          items.push({
            ...item.val(),
            id:item.key,
            data:data
          });
        });
        self.setState({nodes:items});
      }).catch(function(error){
        console.error(error)
      });
    }
  }
  onRoadmapChange(e,select){
    this.setState({currentRoadmap: select.value});
  }
  onNodesChange(e,select){
    this.setState({currentRoadmap: null});
    if(this.props.onSelection)
      this.props.onSelection(this.state.currentRoadmap,select.value);
  }
  onModalClose(){
    this.setState({currentRoadmap: null});
    if(this.props.onCancel)
      this.props.onCancel();
  }
  render(){
    const roadmapOptions = this.state.roadmaps.map(function(item){
      return {key: item.id, value: item.id, text: item.name}
    });
    const nodeOptions = this.state.nodes.map(function(item){
      return {key: item.id, value: item.id, text: item.data.label}
    });
    return(
      <Modal open={this.props.open} onClose={this.onModalClose.bind(this)}>
        <Header>Select node to add</Header>
        <Modal.Content>
          <Grid columns={2}>
            <Grid.Column>
              <Dropdown
                placeholder='Select roadmap'
                selection
                fluid
                onChange={this.onRoadmapChange.bind(this)}
                options={roadmapOptions}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                placeholder='Select node'
                selection
                fluid
                onChange={this.onNodesChange.bind(this)}
                options={nodeOptions}
              />
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
