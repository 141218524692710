import {Component} from 'react';
import {Grid,Button,Dropdown,Label} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
export default class Task extends Component{
  onEdit(){
    let oldVal = this.props.data?this.props.data.label:'';
    let newVal = prompt("Enter label:",oldVal);
    if(newVal===oldVal || newVal===null)
      return;
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid+'/'+this.props.data.id);
    ref.update({
      label: newVal
    });
  }
  onDelete(){
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid+'/'+this.props.data.id);
    ref.remove();
  }
  onDragStart(e){
    e.dataTransfer.setData('data',JSON.stringify(this.props.data));
    e.dataTransfer.effectAllowed = 'copy';
  }
  onPriorityChange(e,select){
    const ref = firebase.database().ref('/tasks/'+this.props.user.uid+'/'+this.props.data.id);
    ref.update({
      priority: select.value
    });
  }
  render(){
    const priorities = [
      {key: 'A1',value:'A1',text:'A1'},
      {key: 'A2',value:'A2',text:'A2'},
      {key: 'A3',value:'A3',text:'A3'},
      {key: 'A4',value:'A4',text:'A4'},
      {key: 'B1',value:'B1',text:'B1'},
      {key: 'B2',value:'B2',text:'B2'},
      {key: 'B3',value:'B3',text:'B3'},
      {key: 'B4',value:'B4',text:'B4'},
      {key: 'B5',value:'B5',text:'B5'},
      {key: 'B6',value:'B6',text:'B6'},
      {key: 'C1',value:'C1',text:'C1'},
      {key: 'C2',value:'C2',text:'C2'},
      {key: 'C3',value:'C3',text:'C3'},
      {key: 'C4',value:'C4',text:'C4'},
      {key: 'C5',value:'C5',text:'C5'},
      {key: 'C6',value:'C6',text:'C6'},
      {key: 'D1',value:'D1',text:'D1'},
      {key: 'D2',value:'D2',text:'D2'},
      {key: 'D3',value:'D3',text:'D3'},
      {key: 'D4',value:'D4',text:'D4'},
      {key: 'D5',value:'D5',text:'D5'},
      {key: 'D6',value:'D6',text:'D6'}
    ]
    const priority = this.props.data.priority || 'D1';
    const priorityColor = ['red','orange','teal','blue'][priority.charCodeAt(0)-65];
    return (
      <Label tag size='mini' className={'editable-task '+priorityColor} draggable='true' onDragStart={this.onDragStart.bind(this)}>
        <label>{this.props.data.label}</label>
        <Button.Group size='mini' className='toolbar'>
          <Dropdown selection options={priorities} onChange={this.onPriorityChange.bind(this)} value={this.props.data.priority||'D1'}/>
          <Button icon='edit' onClick={this.onEdit.bind(this)}/>
          <Button icon='close' color='red' onClick={this.onDelete.bind(this)}/>
        </Button.Group>
      </Label>
    );
  }
};
