import {Component} from 'react';
import {Handle, Position, NodeToolbar} from 'reactflow';
import {Button,Icon,Modal} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import {Link} from 'wouter';

export default class DiagramNode extends Component{
  constructor(props){
    super(props);
    this.state = {
    };
  }
  onEdit(){
    if(this.props.onEdit){
      this.props.onEdit({
        ...this.props.data,
        id: this.props.id
      });
    }
  }
  onTrash(){
    //remove edges
    const refEdges = firebase.database().ref('/diagram/edges/'+this.props.data.userId+'/'+this.props.data.roadmapId);
    refEdges.orderByChild('target').equalTo(this.props.id).get().then(function(s){
      s.forEach(function(item){
        item.ref.remove();
      });
    });
    refEdges.orderByChild('source').equalTo(this.props.id).get().then(function(s){
      s.forEach(function(item){
        item.ref.remove();
      });
    });
    //remove link
    let ref = firebase.database().ref('/roadmaps/'+this.props.data.userId+'/'+this.props.data.link+'/link');
    ref.remove();
    //remove the node
    ref = firebase.database().ref('/diagram/nodes/'+this.props.data.userId+'/'+this.props.data.roadmapId+'/'+this.props.id);
    ref.remove();
  }
  onLink(){
    //add new roadmap
    const refNodes = firebase.database().ref('/roadmaps/'+this.props.data.userId);
    const ref = refNodes.push();
    ref.set({
      name: this.props.data?this.props.data.label:'',
      link: this.props.data.roadmapId+'/'+this.props.id
    });
    const newRoadmapId = ref.key;
    //update current node link
    const refNode = firebase.database().ref('/diagram/nodes/'+this.props.data.userId+'/'+this.props.data.roadmapId+'/'+this.props.id+'/data');
    refNode.update({
      link: newRoadmapId
    });
  }
  render(){
    let link = (<Button icon='pin' onClick={this.onLink.bind(this)}/>)
    if(this.props.data.link){
      link = (
      <Link href={'/roadmap/'+this.props.data.link}>
        <Button icon='linkify' color='blue'/>
      </Link>
      );
    }
    return (
      <div className={'diagram-node '+(this.props.data.link?'linked':'')} style={{backgroundColor: this.props.data.color,boxShadow: '0 0 1px 11px '+this.props.data.color}}>
        <Handle type='target' position={Position.Left}/>
        <Handle type='source' position={Position.Right}/>
        <NodeToolbar>
          <Button.Group size='mini'>
            <Button icon='edit' onClick={this.onEdit.bind(this)}/>
            <Button icon='trash' color='red' onClick={this.onTrash.bind(this)}/>
            {link}
          </Button.Group>
        </NodeToolbar>
        <div>
          {this.props.data?this.props.data.label:''}
          &nbsp;&nbsp;
          {this.props.data.link?<Icon name='linkify'/>:''}
        </div>
      </div>
    );
  }
}
