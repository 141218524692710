import {Component} from 'react';
import './style.scss';
import firebase from 'firebase/compat/app';
import {Grid,Card,Button,Modal} from 'semantic-ui-react';
import NodeSelector from './NodeSelector';
import NodeCard from './NodeCard';
export default class Role extends Component{
  constructor(props){
    super(props);
    this.state={
      roles: [],
      roadmaps: {},
      nodes: null,
      nodeSelectorOpened: false
    }
  }
  componentDidMount(){
    const self = this;
    const refRoadmaps = firebase.database().ref('/roadmaps/'+this.props.user.uid);
    refRoadmaps.on('value',function(s){
      self.setState({
        roadmaps: s.toJSON()
      });
    });

    const refNodes = firebase.database().ref('/diagram/nodes/'+this.props.user.uid);
    refNodes.on('value',function(s){
      self.setState({
        nodes: s.toJSON()
      });
    });
    const refRoles = firebase.database().ref('/roles/'+this.props.user.uid);
    refRoles.on('value',function(s){
      let items=[];
      s.forEach(function(item){
        items.push({
          id: item.key,
          ...item.val()
        });
      });
      self.setState({
        roles: items
      });
    });
  }
  onNewRole(){
    let ref = firebase.database().ref('/roles/'+this.props.user.uid);
    ref = ref.push();
    ref.set({
      name: 'New Role'
    });
  }
  onRename(item){
    let oldVal = item.name;
    let newVal = prompt("Enter label:",oldVal);
    if(newVal===oldVal || newVal===null)
      return;
    let ref = firebase.database().ref('/roles/'+this.props.user.uid+'/'+item.id);
    ref.update({
      name: newVal
    });
  }
  onTrash(item){
    let ref = firebase.database().ref('/roles/'+this.props.user.uid+'/'+item.id);
    ref.remove();
  }
  onAddNode(item){
    this.currentRole=item;
    this.setState({nodeSelectorOpened:true});
  }
  onNodeSelected(roadmapId,nodeId){
    const self = this;
    let ref = firebase.database().ref('/roles/'+this.props.user.uid+'/'+this.currentRole.id+'/nodes');
    ref.once('value').then(function(s){
      let found=false;
      s.forEach(function(item){
        if((roadmapId==item.val().roadmapId) && (nodeId==item.val().nodeId))
          found=true;
      });
      if(!found)
      {
        //add new data when it is not found
        ref.update({
          [nodeId]: {
            roadmapId: roadmapId
          }
        })
      }
      self.setState({nodeSelectorOpened:false});
    });
  }
  onNodeSelectionCanceled(){
    this.currentRole=null;
    this.setState({nodeSelectorOpened:false});
  }
  onNodeClose(roleId,nodeId){
    const ref = firebase.database().ref('/roles/'+this.props.user.uid+'/'+roleId+'/nodes/'+nodeId);
    ref.remove();
  }
  render(){
    const self = this;
    return (
      <Grid celled='internally' className='role'>
        {this.state.roles.map(function(role){
          let cards=''
          if(role.nodes && self.state.nodes){
            cards=Object.keys(role.nodes).map(function(nodeId){

              const node = role.nodes[nodeId];
              if((!self.state.nodes[node.roadmapId]) || (!self.state.nodes[node.roadmapId][nodeId]))
                return '';
              const nodeLabel=self.state.nodes[node.roadmapId][nodeId]['data']?(self.state.nodes[node.roadmapId][nodeId]['data']['label']):'';
              const nodeColor=self.state.nodes[node.roadmapId][nodeId]['data']?(self.state.nodes[node.roadmapId][nodeId]['data']['color']):'#FFFFFF';
              return(
                <NodeCard key={nodeId} nodeLabel={nodeLabel} roadmapLabel={self.state.roadmaps[node.roadmapId]['name']} color={nodeColor} onClose={self.onNodeClose.bind(self,role.id,nodeId)}/>
              );
            });
          }
          return(
            <Grid.Row key={role.id}>
              <Grid.Column floated='left' width={2} verticalAlign='middle' textAlign='center' className='role-name'>
                <strong>{role.name}</strong>
              </Grid.Column>
              <Grid.Column width={12} stretched>
                <Card.Group itemsPerRow={3} className='node-cards'>
                  {cards}
                </Card.Group>
              </Grid.Column>
              <Grid.Column floated='right' verticalAlign='middle' width={2}>
                <Button.Group size='mini' floated='right'>
                  <Button icon='plus' onClick={self.onAddNode.bind(self,role)}/>
                  <Button icon='edit' onClick={self.onRename.bind(self,role)}/>
                  <Button icon='trash' onClick={self.onTrash.bind(self,role)} color='red'/>
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          );
        })}
        <Grid.Row>
          <Button fluid icon='plus' onClick={this.onNewRole.bind(this)}/>
          <NodeSelector
            open={this.state.nodeSelectorOpened} user={this.props.user}
            onCancel={this.onNodeSelectionCanceled.bind(this)}
            onSelection={this.onNodeSelected.bind(this)}
          />
        </Grid.Row>
      </Grid>
    );
  }
};
