import './App.scss';
import 'semantic-ui-css/semantic.min.css';
import {google} from './firebase';
import firebase from 'firebase/compat/app';
import {Component} from 'react';
import {Link, Router, Redirect, Switch} from 'wouter';
import makeMatcher from 'wouter/matcher';
import AuthRoute from './AuthRoute';
import Roadmaps from './roadmap/Roadmaps';
import Schedule from './schedule/Schedule';
import Role from './role/Role';
import Help from './help/Help';
import {Menu,Button,Icon,Segment} from 'semantic-ui-react';

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      user: null,
      currentPath: ''
    }
  }
  componentDidMount(){
    const self = this;
    firebase.auth().onAuthStateChanged(function(user){
      self.setState({user:user});
    });
  }
  login(){
    firebase.auth().signInWithPopup(google).then(function(result){
      let user = result.user;
      console.log(user)
    }).catch(function(error){
      console.error(error);
    })
  }
  logout(){
    firebase.auth().signOut().then(function(){
    }).catch(function(error){
      console.error(error);
    })
  }
  matchLocation(pattern,path){
    const self = this;
    const defaultMatcher = makeMatcher();
    const [match,params] = defaultMatcher(pattern,path);
    if(match){
      if(this.state.currentPath!==path){
        setTimeout(function(){
          self.setState({
            currentPath: path
          });
        },10);
      }
      return [match,params];
    }
    return [false,null];
  }
  render(){
    let loginout;
    if(this.state.user !== null){
      loginout = (<Button onClick={this.logout.bind(this)}>Logout</Button>)
    }
    else{
      loginout = (<Button primary onClick={this.login.bind(this)}>Login</Button>)
    }
    const location = this.state.currentPath;
    return (
      <div className='app'>
        <Menu pointing attached='top' size='mini'>
          <div className='item header'>PIM</div>
          <Link href="/roadmap">
            <a className={'item '+(location.startsWith('/roadmap')?'active':'')}><Icon name='boxes'/>Roadmap</a>
          </Link>
          <Link href="/role">
            <a className={'item '+(location.startsWith('/role')?'active':'')}><Icon name='users'/>Role</a>
          </Link>
          <Link href="/schedule">
            <a className={'item '+(location.startsWith('/schedule')?'active':'')}><Icon name='calendar'/>Schedule</a>
          </Link>
          <Link href="/help">
            <a className={'item '+(location.startsWith('/help')?'active':'')}><Icon name='question'/>Help</a>
          </Link>
          <Menu.Item position='right'>
            {loginout}
          </Menu.Item>
        </Menu>
        <div className='page-container'>
            <Router matcher={this.matchLocation.bind(this)}>
              <Switch>
              <AuthRoute path="/roadmap">
                <Roadmaps user={this.state.user} id={undefined}/>
              </AuthRoute>
              <AuthRoute path="/roadmap/:id">
                {params => <Roadmaps user={this.state.user} id={params.id}/>}
              </AuthRoute>
              <AuthRoute path="/roadmap/:id/:nodeId">
                {params => <Roadmaps user={this.state.user} id={params.id} nodeId={params.nodeId}/>}
              </AuthRoute>
              <AuthRoute path="/role"><Role user={this.state.user}/></AuthRoute>
              <AuthRoute path="/schedule"><Schedule user={this.state.user}/></AuthRoute>
              <AuthRoute path="/help"><Help/></AuthRoute>
            <Redirect to='/roadmap'/>
          </Switch>
            </Router>
        </div>
      </div>
    );
  }
}

export default App;
