import {Component} from 'react';
import {Header} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import {Route} from 'wouter';
export default class AuthRoute extends Component{
  constructor(props){
    super(props);
    this.state = {
      user: null
    }
  }
  componentDidMount(){
    const self = this;
    firebase.auth().onAuthStateChanged(function(user){
      self.setState({user:user});
    });
  }
  render(){
    if(this.state.user)
      return (<Route {...this.props}>{this.props.children}</Route>)
    else
      return (<Header as='h1'>You need to login first</Header>)
  }
};
