import {Component,createRef} from 'react';
import {Menu,Button,Input,Icon} from 'semantic-ui-react';
import {Link} from 'wouter';

export default class Editableinput extends Component{
  constructor(props){
    super(props);
    this.state = {
      editable: false,
      text: '',
      dragging: false
    };
    this.handleRef = createRef();
  }
  componentDidMount(){
    this.setState({text: this.props.item.name});
  }
  onEdit(){
    this.setState({editable: true});
  }
  onSave(){
    this.props.onUpdate(this.state.text);
    this.setState({editable: false});
  }
  onDelete(){
    this.props.onDelete();
    this.setState({editable: false});
  }
  onChange(e,component){
    this.setState({text: component.value});
  }
  onMouseDown(e){
    this.target=e.target;
  }
  onDragStart(e){
    if(this.handleRef.current.contains(this.target)){
      e.dataTransfer.setData('data',JSON.stringify(this.props.item));
      e.dataTransfer.effectAllowed = 'move';
      this.setState({dragging: true});
    }
    else{
      e.preventDefault();
    }
  }
  onDragEnd(e){
    this.setState({dragging: false});
    if(this.props.onDragEnd)
      this.props.onDragEnd(e);
  }
  onDragOver(e){
    const rect = e.target.getBoundingClientRect();
    const y = e.clientY - rect.top;
    let direction='up';
    if(y>rect.height/2)
      direction='down';
    if(this.props.onDragging)
      this.props.onDragging(this.props.index,direction)
  }
  render(){
    let link='';
    if(this.props.item.link){
      link = (
      <Link href={'/roadmap/'+this.props.item.link}>
        <Button className='editable-input-btn' icon='linkify' color='blue'/>
      </Link>
      );
    }
    if(this.state.editable){
      return (
        <Menu.Item className='roadmap-item' index={this.props.index} onDragOver={this.onDragOver.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
          <Input size='mini' action value={this.state.text} onChange={this.onChange.bind(this)}>
            <input/>
              <Button mini icon='check' color='green' onClick={this.onSave.bind(this)}/>
              <Button mini icon='trash' color='red' onClick={this.onDelete.bind(this)}/>
          </Input>
        </Menu.Item>
      );
    }
    else{
      return (
        <Menu.Item draggable="true" index={this.props.index} onDragOver={this.onDragOver.bind(this)} onMouseDown={this.onMouseDown.bind(this)} onDragStart={this.onDragStart.bind(this)} onDragEnd={this.onDragEnd.bind(this)} active={this.props.active} className={'roadmap-item '+(this.props.item.link?'linkable':'')+(this.state.dragging?' dragging':'')}>
          <span ref={this.handleRef} className='handle'></span>
          <Link href={'/roadmap/'+this.props.item.id}>
            <Button basic className='text'>
              {this.props.item.name}
              &nbsp;&nbsp;&nbsp;
              {this.props.item.link?<Icon name='linkify'/>:''}
            </Button>
          </Link>
          <Button icon='edit' className='editable-input-btn' onClick={this.onEdit.bind(this)}/>
          {link}
        </Menu.Item>
      );
    }
  }
}
