import {Component} from 'react';
import {Modal,Form,Input,TextArea,Button} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';

export default class EditDlg extends Component{
  constructor(props){
    super(props);
    this.state={
      label: '',
      description: '',
      color: '#FFFFFF'
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.open!==this.props.open){
      this.setState({
        label: nextProps.data.label||'',
        description: nextProps.data.description||'',
        color: nextProps.data.color||'#FFFFFF'
      });
    }
  }
  onSave(){
    const self = this;
    const ref = firebase.database().ref('/diagram/nodes/'+this.props.data.userId+'/'+this.props.data.roadmapId+'/'+this.props.data.id+'/data');
    ref.update({
      label: this.state.label,
      color: this.state.color,
      description: this.state.description
    });
    if(this.props.onClose)
      this.props.onClose();
  }
  onChange(e,change){
    this.setState({
      [change.name]:change.value
    });
  }
  render(){
    return(
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>
          <h1>Edit Node</h1>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Label</label>
                <Input name='label' onChange={this.onChange.bind(this)} value={this.state.label}/>
              </Form.Field>
              <Form.Input width={2} name='color' onChange={this.onChange.bind(this)} label='Color' type='color' value={this.state.color}/>
            </Form.Group>
            <Form.Field>
              <label>Description</label>
              <TextArea name='description' onChange={this.onChange.bind(this)} value={this.state.description}/>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.onSave.bind(this)}>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }
};
