// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/database';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyCwJgcOV2EGP8fhueSj3CIWByT1G3ZENZQ",
  authDomain: "pimhex.firebaseapp.com",
  databaseURL: "https://pimhex-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pimhex",
  storageBucket: "pimhex.appspot.com",
  messagingSenderId: "599770987180",
  appId: "1:599770987180:web:4288472ccda2d744a9fd09"
};
// Initialize Firebase
firebase.initializeApp(config);

export const google = new firebase.auth.GoogleAuthProvider();
export const db = firebase.database();
