import {Component} from 'react';
import './style.scss';
import {Menu,Button} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import Roadmap from './Roadmap';
import FlowDiagram from './FlowDiagram.jsx';
import {Redirect} from 'wouter';

export default class Roadmaps extends Component{
  constructor(props){
    super(props);
    this.state={
      roadmaps:[],
      order: [],
      placeholderIndex: 1000
    }
  }
  componentDidMount(){
    const self = this;
    const roadmaps = firebase.database().ref('/roadmaps/'+this.props.user.uid);
    let minOrder=0;
    roadmaps.on('value',function(data){
      let roadmaps = [];
      data.forEach(function(item){
        roadmaps.push({
          ...item.val(),
          id:item.key
        });
      });
      roadmaps.sort(function(a,b){
        if(a.order<b.order)
          return -1;
        if(a.order>b.order)
          return 1;
        return 0
      });
      //get current order of items
      let order = roadmaps.map(function(item){
        return item.id;
      });
      //add placeholder to last position
      order.push('-');
      self.setState({
        roadmaps:roadmaps,
        order: order
      });
    });
  }
  onAddRoadmap(){
    const roadmaps = firebase.database().ref('/roadmaps/'+this.props.user.uid);
    const data = roadmaps.push();
    data.set({
      name: 'Unnamed'
    });
  }
  onUpdateRoadmap(id,val){
    const item = firebase.database().ref('/roadmaps/'+this.props.user.uid+'/'+id);
    item.update({
      name: val
    });
  }
  onDeleteRoadmap(item){

    //cleanup its child nodes/edges too
    let ref = firebase.database().ref('/diagram/nodes/'+this.props.user.uid+'/'+item.id);
    ref.remove();
    ref = firebase.database().ref('/diagram/edges/'+this.props.user.uid+'/'+item.id);
    ref.remove();
    //remove link
    if(item.link)
    {
      ref = firebase.database().ref('/diagram/nodes/'+this.props.user.uid+'/'+item.link+'/data/link');
      ref.remove();
    }
    //finally remove the item
    ref = firebase.database().ref('/roadmaps/'+this.props.user.uid+'/'+item.id);
    ref.remove();
  }
  onDragging(index,direction){
    if(direction=='up')
      this.setState({placeholderIndex: index});
    else
      this.setState({placeholderIndex: index+1});
  }
  onDrop(e){
    e.preventDefault();
    const self = this;
    const droppedItem=JSON.parse(e.dataTransfer.getData('data'));
    this.state.roadmaps.forEach(function(item,i){
      let index=i;
      if(item.id==droppedItem.id)
        index=self.state.placeholderIndex;
      else if(index>=self.state.placeholderIndex)
        index=i+1;
      const ref = firebase.database().ref('/roadmaps/'+self.props.user.uid+'/'+item.id);
      ref.update({
        order: index
      });
    });
    this.setState({placeholderIndex: 100});
  }
  onPlaceholderDragOver(e){
    e.preventDefault();
  }
  onPlaceholderDragEnd(e){
    this.setState({placeholderIndex: 100});
  }
  render(){
    const self = this;
    if((!this.props.id) && this.state.roadmaps.length){
      return (<Redirect to={'/roadmap/'+this.state.roadmaps[0].id}/>);
    }
    const items = this.state.roadmaps.map(function(item,i){
      const index=(i>=self.state.placeholderIndex)?i+1:i;
      if(item.id===self.props.id)
        return(
          <Roadmap key={item.id} index={index} item={item} active={true} onUpdate={self.onUpdateRoadmap.bind(self,item.id)} onDelete={self.onDeleteRoadmap.bind(self,item)} onDragging={self.onDragging.bind(self)} onDragEnd={self.onPlaceholderDragEnd.bind(self)}/>
        )
      else
        return(
          <Roadmap item={item} key={item.id} index={index} onUpdate={self.onUpdateRoadmap.bind(self,item.id)} onDelete={self.onDeleteRoadmap.bind(self,item)} onDragging={self.onDragging.bind(self)} onDragEnd={self.onPlaceholderDragEnd.bind(self)}/>
        )
    })
    if(this.state.placeholderIndex<=items.length){
      items.splice(this.state.placeholderIndex,0,
        (<Menu.Item key={1000} onDrop={this.onDrop.bind(this)} onDragOver={this.onPlaceholderDragOver.bind(this)} onDragEnd={this.onPlaceholderDragEnd.bind(this)} className='drag-placeholder' index={this.state.placeholderIndex}>&nbsp;</Menu.Item>));
    }
    return (
      <div className='roadmaps'>
        <Menu pointing vertical size='large' borderless>
          {items}
          <Menu.Item>
            <Button fluid onClick={this.onAddRoadmap.bind(this)}>Add</Button>
          </Menu.Item>
        </Menu>
        <FlowDiagram user={this.props.user} roadmapId={this.props.id}/>
      </div>
    );
  }
};
